import React from 'react';
import styled from 'styled-components';

const RowContainer = styled.div`
  display: flow-root;
  margin: 0 auto;
  max-width: var(--width);
  padding: 0 var(--padding);
  position: relative;
  width: 100%;
`;

const Row = ({ children, className, marginBottom }) => {
  const mb = marginBottom ? `${marginBottom}px` : '';
  return (
    <RowContainer className={className} style={{ marginBottom: mb }}>
      {children}
    </RowContainer>
  );
};

export default Row;
