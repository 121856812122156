import React from 'react';
import Row from '../../components/row/row';
import Social from '../../components/social/social';
import UserAccountNav from './userAccountNav';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  background-color: var(--c-theme-black);
`;

const HeaderRow = styled(Row)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const HeaderTop = () => {
  return (
    <HeaderContainer>
      <HeaderRow>
        <Social />
        <UserAccountNav />
      </HeaderRow>
    </HeaderContainer>
  );
};

export default HeaderTop;
