import React from 'react';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import { isLoggedIn, logout } from '../services/auth';
import { FaExternalLinkAlt } from 'react-icons/fa';
import Row from './row/row';
import { GetFooter } from '../hooks/footer';
import styled, { css } from 'styled-components';

const colorThemeBlack = '#3d3c3a';
const colorThemeWhite = '#f2f2f0';
const colorThemeGray = '#eeeeee';

const MainFooter = styled.footer`
  background-color: ${colorThemeBlack};
  color: ${colorThemeWhite};
  width: 100%;
`;

const Copyright = styled.p`
  font-size: 14px;
  padding: 20px 0;
  text-align: center;
`;

const Top = styled.div`
  background-color: ${colorThemeGray};
  padding: 20px 0;
`;

const Heading = styled.h2`
  font-size: 1.25rem;
`;

const Item = styled.li`
  color: ${colorThemeBlack};
  line-height: 1;
  margin-bottom: 15px;
`;

const Grid = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 20px;
  position: relative;
  width: 100%;
`;

const linkStyles = css`
  color: var(--c-link);

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

const GatsbyLink = styled(Link)`
  ${linkStyles}
`;

const FooterLink = styled.a`
  ${linkStyles}
`;

const ExternalLinkIcon = styled(FaExternalLinkAlt)`
  font-size: 10px;
  margin-left: 5px;
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const footer = GetFooter();
  const footerExists = Array.isArray(footer) && footer.length > 0;

  return (
    <MainFooter>
      {isLoggedIn() && footerExists && (
        <Top>
          <Grid>
            {footer
              .sort((a, b) => a.order - b.order)
              .map(({ id, heading, footer }) => {
                const footerLinksExist = Array.isArray(footer) && footer.length > 0;
                return (
                  <div key={id}>
                    <Heading>{heading}</Heading>
                    {footerLinksExist && (
                      <ul>
                        {footer.map(({ id, external, link, text }) => {
                          const regex = /^(https?):/;
                          const isExternal = regex.test(link);
                          return (
                            <Item key={id}>
                              {external || isExternal ? (
                                <FooterLink href={link} target="_blank" rel="noreferrer">
                                  {text} <ExternalLinkIcon />
                                </FooterLink>
                              ) : (
                                <GatsbyLink to={link}>{text}</GatsbyLink>
                              )}
                            </Item>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                );
              })}

            <div>
              <Heading>Contact</Heading>
              <ul>
                <Item>
                  <strong style={{ display: 'block' }}>Email</strong>
                  <FooterLink
                    href="mailto:team@jacobuniversity.com"
                    style={{ marginBottom: '10px', display: 'inline-block' }}
                  >
                    team@jacobuniversity.com
                  </FooterLink>
                </Item>
              </ul>
            </div>

            <div>
              <ul>
                <Item>
                  <a
                    href="/"
                    onClick={(event) => {
                      event.preventDefault();
                      logout(() => navigate(`/app/login`));
                    }}
                    className="button button--no-expand"
                  >
                    Logout
                  </a>
                </Item>
              </ul>
            </div>
          </Grid>
        </Top>
      )}

      <Row>
        <Copyright>
          &copy; {new Date().getFullYear()} {data.site.siteMetadata.title}. All Rights Reserved.
        </Copyright>
      </Row>
    </MainFooter>
  );
};

export default Footer;
