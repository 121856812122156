import axios from 'axios';
import { navigate } from 'gatsby';

export const isBrowser = () => typeof window !== 'undefined';

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('seintUser')
    ? JSON.parse(window.localStorage.getItem('seintUser'))
    : {};

const setUser = (user) => window.localStorage.setItem('seintUser', JSON.stringify(user));

const setCookie = (user) => {
  const now = new Date();
  now.setTime(now.getTime() + 1 * 3600 * 1000);
  document.cookie = `artist=${user}; expires=${now.toUTCString()}; path=/`;
};

export const handleLogin = ({ email, password }, errors = {}, setErrors) => {
  // Reset errors.
  setErrors({
    ...errors,
    email: { ...errors.email, error: false },
    password: { ...errors.password, error: false },
  });

  axios
    .post(`${process.env.GATSBY_ADMIN_URL}/auth/local/`, {
      identifier: email,
      password: password,
    })
    .then((response) => {
      const { jwt, user } = response.data;
      setUser({
        artistId: user.artist_id,
        artistUrl: user.artist_url,
        firstName: user.first_name,
        jwt,
        lastName: user.last_name,
        userId: user.id,
      });
      setCookie(user.artist_id);
      navigate('/app/welcome');
    })
    .catch((error) => {
      if (error?.response?.data?.data?.[0]?.messages?.[0]?.id === 'Auth.form.error.invalid') {
        setErrors({ ...errors, password: { ...errors.password, error: true } });
      }
    });

  return false;
};

export const isLoggedIn = () => {
  const user = getUser();
  return !!user.artistId;
};

export const logout = (callback) => {
  setUser({});
  callback();
};
