import { css } from 'styled-components';

export const visualHide = css`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal;
`;

export const unVisualHide = css`
  clip: auto;
  clip-path: none;
  height: auto;
  margin: 0;
  overflow: visible;
  padding: 0;
  position: static;
  width: auto;
`;
