import { useStaticQuery, graphql } from 'gatsby';

export const GetSocial = () => {
  return useStaticQuery(
    graphql`
      query {
        allStrapiSocial {
          nodes {
            links {
              id
              link
              text
            }
            name
            order
          }
        }
      }
    `
  ).allStrapiSocial.nodes;
};
