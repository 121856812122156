import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { createGlobalStyle } from 'styled-components';
import { visualHide } from '../utils';
import Header from './header/header';
import Main from './main/main';
import Footer from './footer';
import './layout.css';
import '../assets/scss/index.scss';

const GlobalStyle = createGlobalStyle`
  :root {
    --width: 1180px;
    --padding: 20px;
    --radius: 5px;
    --spacing: 25px;
    --f-quicksand: 'Quicksand', sans-serif;
    --f-coral-blush-serif: 'Coral Blush Serif', serif;
    --ff-body: var(--f-quicksand);
    --ff-heading: var(--f-coral-blush-serif);
    --fw-light: 300;
    --fw-normal: 400;
    --fw-medium: 500;
    --fw-semi-bold: 600;
    --fw-bold: 700;
    --fw-extra-bold: 800;
    --fw-black: 900;
    --c-black: #000000;
    --c-white: #ffffff;
    --c-gray: #808080;
    --c-cultured-1: #eeeeee;
    --c-cultured: #f2f2f0;
    --c-jet: #3d3c3a;
    --c-light-steel-blue: #b3c3d5;
    --c-misty-rose: #fee8e5;
    --c-pale-pink: #f7d0cb;
    --c-platinum: #ece9e3;
    --c-shadow-blue: #768ca4;
    --c-silver-sand: #c3cace;
    --c-timberwolf: #d4d2cc;
    --c-violet-red: #f06394;
    --c-error: #ea595e;
    --c-warning: #f0bd2b;
    --c-success: #59ea9d;
    --c-primary: var(--c-pale-pink);
    --c-secondary: var(--c-timberwolf);
    --c-tertiary: var(--c-misty-rose);
    --c-theme-black: var(--c-jet);
    --c-theme-white: var(--c-cultured);
    --c-theme-gray: var(--c-cultured-1);
    --c-link: var(--c-shadow-blue);
    --c-link-hover: var(--c-light-steel-blue);
  }

  .h-visual-hide {
    ${visualHide}
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const isBrowser = () => typeof window !== 'undefined';

  isBrowser() &&
    window.addEventListener('beforeunload', () => {
      const cookie = document.cookie.indexOf('artist=') >= 0;

      if (!cookie) {
        localStorage.removeItem('seintUser');
      }

      return '';
    });

  return (
    <>
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
