import React from 'react';
import { Link } from 'gatsby';
import { isLoggedIn } from '../../services/auth';
import { devices, visualHide, unVisualHide } from '../../utils';
import PropTypes from 'prop-types';
import logo from '../../assets/images/logo.png';
import Row from '../row/row';
import HeaderTop from './headerTop';
import styled from 'styled-components';

const HeaderMain = styled.header`
  background-color: var(--c-white);
  box-shadow: 0 0 2px 1px rgba(0 0 0 / 15%);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
`;

const Container = styled(Row)`
  align-items: center;
  display: grid;
  gap: 20px 50px;
  padding: 10px var(--padding);

  @media ${devices.tablet} {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const LogoLink = styled(Link)`
  display: block;
  margin: 0 auto;
  max-width: 300px;
`;

const Logo = styled.img`
  display: block;
  height: 25px;

  @media ${devices.tablet} {
    height: auto;
  }
`;

const SkipLink = styled.a`
  ${visualHide}

  &:focus,
  &:hover {
    ${unVisualHide}

    background-color: var(--c-primary);
    border: 0;
    border-radius: 5px;
    color: var(--c-white);
    font-size: 20px;
    left: 0;
    padding: 10px 15px;
    position: fixed;
    top: 0;
    z-index: 10000;
  }
`;

const Header = () => {
  const logoLink = isLoggedIn() ? '/app/welcome' : '/';

  return (
    <>
      <SkipLink href="#main">Skip navigation to main content.</SkipLink>
      <HeaderMain>
        <HeaderTop />
        <Container>
          <LogoLink to={logoLink}>
            <Logo src={logo} alt="Jacob University" />
          </LogoLink>
        </Container>
      </HeaderMain>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
