import React from 'react';
import { Link } from 'gatsby';
import { isLoggedIn } from '../../services/auth';
import { FaUser } from 'react-icons/fa';
import styled from 'styled-components';

const UserContainer = styled.div`
  align-items: center;
  color: var(--c-white);
  display: flex;
  font-size: 14px;
`;

const UserIcon = styled(FaUser)`
  margin-right: 5px;
`;

const ProfileLink = styled(Link)`
  align-items: center;
  display: flex;

  &:focus,
  &:hover {
    color: var(--c-primary);
  }
`;

const UserAccountNav = () => {
  return (
    <UserContainer>
      {isLoggedIn() && (
        <ProfileLink to="/app/profile">
          <UserIcon />
          My Profile
        </ProfileLink>
      )}
    </UserContainer>
  );
};

export default UserAccountNav;
