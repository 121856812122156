export const sizes = {
  mobileMedium: '375px',
  mobileLarge: '425px',
  tablet: '768px',
  laptop: '1024px',
  container: '1180px',
  laptopLarge: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileMedium: `(min-width: ${sizes.mobileMedium})`,
  mobileLarge: `(min-width: ${sizes.mobileLarge})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  container: `(min-width: ${sizes.container})`,
  laptopLarge: `(min-width: ${sizes.laptopLarge})`,
  desktop: `(min-width: ${sizes.desktop})`,
};
