import React from 'react';
import { FaFacebookSquare, FaTelegram, FaInstagram } from 'react-icons/fa';
import { ImYoutube2 } from 'react-icons/im';
import { IoLogoVenmo, IoLogoTiktok } from 'react-icons/io5';
import SocialItem from './socialItem';
import { isLoggedIn } from '../../services/auth';
import styled from 'styled-components';
import { GetSocial } from '../../hooks/social';

const SocialUl = styled.ul`
  display: inline-flex;
`;

const socialIcons = {
  facebook: <FaFacebookSquare />,
  youtube: <ImYoutube2 size={30} />,
  telegram: <FaTelegram />,
  instagram: <FaInstagram />,
  venmo: <IoLogoVenmo />,
  tiktok: <IoLogoTiktok />,
};

const Social = () => {
  const social = GetSocial() ?? [];

  return (
    isLoggedIn() && (
      <SocialUl>
        {social
          .sort((a, b) => a.order - b.order)
          .map(({ name, links }) => {
            const id = crypto.randomUUID();
            const style = name.toLowerCase() === 'youtube' ? { padding: '2px 0 1px' } : {};
            return (
              <SocialItem key={id} link={links[0].link} name={name} links={links} style={style}>
                {socialIcons[name.toLowerCase()]}
              </SocialItem>
            );
          })}
      </SocialUl>
    )
  );
};

export default Social;
