import React from 'react';
import styled from 'styled-components';

const MainElement = styled.main`
  min-height: 100vh;
  position: relative;
  width: 100%;
`;

const Main = ({ children }) => {
  return <MainElement id="main">{children}</MainElement>;
};

export default Main;
