import { useStaticQuery, graphql } from 'gatsby';

export const GetFooter = () => {
  return useStaticQuery(
    graphql`
      query {
        allStrapiFooter {
          nodes {
            id
            heading
            order
            footer {
              id
              external
              link
              text
            }
          }
        }
      }
    `
  ).allStrapiFooter.nodes;
};
