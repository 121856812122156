import React from 'react';
import styled from 'styled-components';

const SubMenuUl = styled.ul`
  box-shadow: 0 1px 8px 1px rgba(0 0 0 / 15%);
  display: none;
  position: absolute;
`;

const SocialLi = styled.li`
  position: relative;
  z-index: 20;

  &:not(:last-child) {
    margin-right: 20px;
  }

  &:focus,
  &:hover {
    ${SubMenuUl} {
      display: block;
    }
  }
`;

const SocialLink = styled.a`
  color: var(--c-white);
  display: block;
  padding: 8px 0;

  &:focus,
  &:hover {
    color: var(--c-primary);
  }

  svg {
    display: block;
  }
`;

const SubMenuLi = styled.li`
  border-bottom: 1px solid var(--c-theme-black);
  width: 180px;
`;

const SubMenuLink = styled.a`
  background-color: var(--c-white);
  color: var(--c-theme-black);
  display: block;
  font-size: 14px;
  padding: 10px;
`;

const SocialItem = (props) => {
  return (
    <SocialLi>
      <SocialLink href={props.link} rel="noopener noreferrer" target="_blank" style={props.style}>
        {props.children}
        <span className="h-visual-hide">{props.text}</span>
      </SocialLink>
      {props.links && (
        <SubMenuUl>
          {props.links.map((link, index) => (
            <SubMenuLi key={index}>
              <SubMenuLink href={link.link} rel="noopener noreferrer" target="_blank">
                {link.text}
              </SubMenuLink>
            </SubMenuLi>
          ))}
        </SubMenuUl>
      )}
    </SocialLi>
  );
};

export default SocialItem;
